<template>
    <div>
      <!-- <HomePage /> -->
      <HeaderPage />
      <!-- <MainPage /> -->
      <!-- <AboutPage /> -->
      <FooterPage />
    </div>
</template>

<script>
  // import HomePage from "./components/HomePage.vue"
  import HeaderPage from './components/HeaderPage.vue';
  import FooterPage from './components/FooterPage.vue';
  // import MainPage from './components/MainPage.vue'
  // import AboutPage from './components/AboutPage';

  export default {
    name: 'App',
    components: {
      // HomePage,
      HeaderPage,
      FooterPage
      // MainPage
      // AboutPage
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
