<template>
    <div>
        <div class="header">
            <div class="logo">
                <h1>Glow & Glow</h1>
            </div>
            <nav class="nav-links">
                <router-link to="/" class="links">Home</router-link>
                <router-link to="/lamps" class="links">Lamps</router-link>
                <router-link to="/about" class="links">About</router-link>
                <router-link to="/contact" class="links">Contact</router-link>
            </nav>
            <div class="icons">
                <ul>
                    <li><i class="fas fa-magnifying-glass"></i></li>
                    <li><i class="fas fa-shop"></i></li>
                    <li><i class="fas fa-bars"></i></li>
                </ul>
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'HeaderPage',
    }
</script>

<style scoped>
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: #171717;
        color:white;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0px 20px;
        width: 100%;
        z-index: 100;
        /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif; */
        font-family: 'Montserrat', sans-serif;
    }
    .logo {
        color:white;
        cursor: pointer;
    }
    .links {
        color:white;
        text-decoration: none;
        font-size: 17px;
        padding: 0px 20px;
    }
    .icons ul {
        display: flex;
        list-style-type: none;
        padding-right: 30px;
        cursor: pointer;
    }
    .icons ul li i {
        font-size: 17px;
        padding: 0px 10px;
    }
</style>