<template>
    <div class="about-header">
        <h1>ABOUT US</h1>
    </div>
    <div class="about">
        <div class="text-container">
            <h1>We’re distinct by design</h1>
            <p>Glow & Glow was founded in 2024 with one objective: 
                to be the finest destination to shop for the best home decor lighting fixtures, 
                and accessories at budget_friendly prices. We offer thousands of products including chandeliers, 
                ceiling lights and fanlights to provide a variety of traditional, 
                industrial and contemporary home decor solutions.
            </p>
        </div>
        <div class="about-content">
            <div class="image-container">
                <img src="../assets/man-light.jpg" alt="Man with light" width="600px" height="375px">
            </div>
            <div class="text-container">
                <h1>We have a professional designer team</h1>
                <p>Great design thrills us more than anything else. 
                    The Glow & Glow team is comprised of seasoned professionals serving residential, 
                    trade professionals, and commercial customers with premium_quality products to create a perfect aura in your interiors or exteriors. 
                    The result? With our dedicated team of professional designers, 
                    we look to maintain our high standards of quality and performance.
                </p>
            </div>
        </div>
        <div class="text">
            <h1>“When it comes to the comfort of your home, we believe that comfort and simplicity are key, to creating a great product.”</h1>
        </div>
        <div class="about-content">
            <div class="text-container">
                <h1>We value the ecosystem</h1>
                <p>We're making strides to lessen our environmental footprint.
                     That implies responsible retailing to us. 
                    To get there, we've come up with a set of targets. Such as eradicating excessive plastic from our product packaging and 
                    assuring that even the most ecologically impacting materials we use are supplied organically. 
                    Our LED lights do not contain mercury and therefore it is safe for the environment and can be replaced easily.
                </p>
            </div>
            <div class="image-container">
                <img src="../assets/woman-light.jpg" alt="Man with light" width="600px" height="375px">
            </div>
        </div>
        <div class="about-content">
            <div class="image-container">
                <img src="../assets/team.jpg" alt="Man with light" width="600px" height="375px">
            </div>
            <div class="text-container">
                <h1>We’re dedicated to our customers</h1>
                <p>At Glow & Glow, we offer an outstanding customer experience that includes a gallery of insights, 
                    inspiration, helpful content, comprehensive product description, quick service, and assistance throughout our customers' projects. 
                    Hardly anything gives us pleasure than seeing our customers embracing our products in their interior decor, 
                    from feedback on our official websites to our 74.8k Instagram followers.
                </p>
            </div>
        </div>
        <div class="about-content">
            <div class="text-container">
                <h1>Vision</h1>
                <p>
                    To be the leading lighting company in Nigeria, West Africa and in Africa. 
                    Giving nothing but the best quality lighting products at a very reasonable and competitive price.
                </p>
            </div>
            <div class="text-container">
                <h1>Mission</h1>
                <p>
                    Set standards for Quality lighting. 
                    Being the leading provider of innovative lighting designs and lighting fixtures to both residential and commercial users. 
                    We also aim to unleash the talents of our employees delivering superior value to our customers,
                     providing innovative solutions for the communities we serve.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutPage',
    }
</script>

<style scoped>
   .about {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        color: black;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 20px;
    }
   .about-header {
        text-align: center;
        color: white;
        margin-bottom: 20px;
        padding: 100px 0px;
        font-size: 60px;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/bg.jpg');
        background-size: cover;
        background-position: center;
    }
   .about-content {
        width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 3rem;
        display: flex;
        padding: 40px 0px;
    }
   .image-container {
        flex-basis: 40%;
        margin: 20px;
    }
   .text-container {
        flex-basis: 50%;
        margin: 20px;
    }
   .image-container img {
        width: 600px;
        height: 400px;
    }
    .text h1{
        font-weight: 300;
    }
    .text-container h1 {
        font-weight: 300;
    }
    .text-container h3 {
        font-size: 30px;
    }
    .text-container p {
        font-size: 18px;
    }
</style>
