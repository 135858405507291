<template>
    <div class="lamp-section">
        <div class="container">
            <h2>LAMP SECTIONS</h2>
            <p>Find the perfect lamp for your style and needs.</p>
            <div class="lamp-categories">
              <router-link to="/pendant" class="links">
                <div class="card1">

                  <img src="../assets/2.jpeg" alt="Pendant Lamp" width="125px">

                  <div class="text-content1">
                    <h1>PENDANT LAMPS</h1>
                    <p>View more ___</p>
                  </div>

                </div>
              </router-link>
              
              <router-link to="/floor" class="links">
                <div class="card2">

                  <img src="../assets/8.jpeg" alt="Floor Lamp"> 

                  <div class="text-content2">
                    <h1>FLOOR LAMPS</h1>
                    <p>View more ___</p>
                  </div>
                </div>
              </router-link>

              <router-link to="/table" class="links">
                <div class="card3">

                  <img src="../assets/16.jpg" alt="Floor Lamp"> 

                  <div class="text-content3">
                    <h1>TABLE LAMPS</h1>
                    <p>View more ___</p>
                  </div>
                </div>
              </router-link>

              <router-link to="/wall" class="links">
                <div class="card4">

                  <img src="../assets/6.jpg" alt="Wall Lamp"> 

                  <div class="text-content4">
                    <h1>WALL LAMPS</h1>
                    <p>View more ___</p>
                  </div>
                </div>
              </router-link>

          </div>
        </div>
    </div>
</template>
  

<script>
    export default {
        name: 'LampSection',
    }
</script>

<style scoped>
.lamp-section {
  background-color: #fff;
  padding: 50px 0;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.lamp-section h2, p {
  font-family: 'Montserrat', sans-serif;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  color: black;
}

h1 {
  color: black;
  margin-bottom: 20px;
}

p {
  color: black;
  margin-bottom: 40px;
  font-family: 'Montserrat', sans-serif;
}

.lamp-positions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.lamp-categories {
  margin: 10px;
  text-align: left;
  justify-content: center;
}

.lamp-categories h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.lamp-categories img {
  width: 250px;
  object-fit: cover;
}



/* card */

.card1 {
  display: flex;
  width: 60.5%; /* Adjust width as needed */
  background-color: #dddddd;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
}

.card1:hover {
    transform: scale(1.06); 
}

.card1 img {
  width: 250px;
  object-fit: cover;
  margin: 0px;
}

.text-content1 {
  padding-top: 100px;
  padding-left: 20px;
}

.text-content1 h1 {
  margin-bottom: 10px;
  color: black;
}

.text-content1 p {
  font-size: 1rem;
  line-height: 1.5;
}



/* card2  */


.card2 {
  display: flex;
  width: 40%;
  height: 45%;
  background-color: #eae9e7;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
  margin-left: 600px;
  margin-top: -250px;
}

.card2:hover {
    transform: scale(1.06); 
}

.card2 img {
  width: 230px;
  height: 450px;
  object-fit: cover;
  margin: 0px;
  order: 1;
}

.text-content2 {
  padding-top: 450px;
  padding-left: 20px;
  order: 0;
}

.text-content2 h1 {
  margin-bottom: 10px;
  color: black;
}

.text-content2 p {
  font-size: 1rem;
  line-height: 1.5;
}


/* card 3 */

.card3 {
  display: flex;
  width: 30%;
  height: 75%;
  background-color: #f0e8e1;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
  margin-top: -390px;
  height: 25%;
}

.card3:hover {
  transform: scale(1.06); 
}

.card3 img {
  width: 150px;
  height: 340px;
  object-fit: cover;
  margin: 0px;
  order: 1;
}

.text-content3 {
  padding-top: 190px;
  padding-left: 20px;
  order: 0;
}

.text-content3 h1 {
  margin-bottom: 10px;
  color: black;
}

.text-content3 p {
  font-size: 1rem;
  line-height: 1.5;
}


/* card 4 */

.card4 {
  display: flex;
  width: 30%;
  height: 75%;
  background-color: #dddddd;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
  margin-top: -376px;
  height: 25%;
  margin-left: 300px;
}

.card4:hover {
  transform: scale(1.06); 
}

.card4 img {
  width: 150px;
  height: 340px;
  object-fit: cover;
  margin: 0px;
  order: 1;
}

.text-content4 {
  padding-top: 190px;
  padding-left: 20px;
  order: 0;
}

.text-content4 h1 {
  margin-bottom: 10px;
  color: black;
}

.text-content4 p {
  font-size: 1rem;
  line-height: 1.5;
}

.links {
    text-decoration: none;
}


</style>

