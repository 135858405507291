<template>
    <div>
        <div>
            <!-- <HeaderPage /> -->
        </div>
        <div>
            <MainPage />
        </div>
        <div>
            <LampSection />
        </div>
        <div>
            <PopularPage />
        </div>
        <div>
            <!-- <FooterPage /> -->
        </div>
    </div>
</template>

<script>
    // import HeaderPage from './HeaderPage.vue'
    import MainPage from './MainPage.vue'
    import LampSection from './LampSection.vue'
    import PopularPage from './PopularPage.vue'
    // import FooterPage from './FooterPage.vue'

    export default {
        name: 'HomePage',
        components: {
            // HeaderPage,
            MainPage,
            LampSection,
            PopularPage,
            // FooterPage
        }
    };
</script>

<style scoped>

</style>