<template>
    <footer>
        <div class="container">
          <div class="row">

            <div class="footer-col">
              <h4>Upcoming Event:</h4>
              <ul>
                <li>Check our website for upcoming events and promotions.</li>
              </ul>
            </div>
            
            <div class="footer-col">
              <h4>Payment Options:</h4>
              <ul>
                <li>We accept cash, all major credit cards, and mobile payments.</li>
              </ul>
            </div>

            <div class="footer-col">
              <h4>Opening Hours:</h4>
              <ul>
                <li>Monday - Friday</li>
                <h4>9:00 AM - 10:00 PM</h4>
              </ul>
            </div>

            <div class="footer-col">
              <h4>Connect With Us:</h4>
              <ul>
                <i class="fa-brands fa-facebook"></i>
                <i class="fa-brands fa-twitter"></i>
                <i class="fa-brands fa-instagram"></i>
                <i class="fa-solid fa-globe"></i>
              </ul>
            </div>
            
            <div class="footer-col">
              <h4>Categories:</h4>
              <ul>
                <li><router-link to="" class="links">Pendant Lamps</router-link></li>
                <li><router-link to="" class="links">Table Lamps</router-link></li>
                <li><router-link to="" class="links">Wall Lamps</router-link></li>
                <li><router-link to="" class="links">Floor Lamps</router-link></li>
              </ul>
            </div>

            <div class="footer-col">
              <h4>Contact Info:</h4>
              <ul>
                <li><i class="fa-solid fa-phone"></i>08123456789</li>
                <li><i class="fa-regular fa-envelope"></i>glow&glow.com</li>
                <li><i class="fa-solid fa-location-dot"></i>123 Main Street, Lagos, Nigeria, ZIP</li>
              </ul>
            </div>

          </div>
        </div>
        <p> &copy; 2024 Glow & Glow. All rights reserved.</p>
    </footer>
</template>

<script>
export default {
    name: 'FooterPage',
}
</script>

<style scoped>

footer .container{
    width: 100%;
    margin-left: auto;
    display: flex;
    justify-content: center;
}

footer{
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif; */
    font-family: 'Montserrat', sans-serif;
    padding: 70px 0px;
    background-color: black;
    color: white;
}

footer .row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.links {
    text-decoration: none;
    color: white;
    font-size: 14px;
}

ul :hover{
  color: rgb(176, 174, 174);
  cursor: pointer;
}

.footer-col{
    width: 33.33%;
    padding: 0px 15px;
    box-sizing: border-box;
    padding-left: 35px;

    @media (max-width: 768px) {
    width: 100%; /* Full width on screens below 768px */
  }
}

.footer-col i{
    cursor: pointer;
    padding-right: 10px;
}

footer h4{
    margin-bottom: 5px;
    position: relative;
    color: rgb(176, 174, 174);
    text-align: left;
}

footer ul{
    list-style: none;
    padding-left: 0px;
    text-align: left;
    font-size: 14px;
}

.footer i :hover {
    color: rgb(176, 174, 174);
}

footer p{
    text-align: center;
    color: rgb(176, 174, 174);
}
</style>