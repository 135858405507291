<template>
  <div class="order-form-container">
    <h2>ORDER</h2>
    <form id="order-form">
      <fieldset>
        <legend>Contact Information</legend>
        <div class="form-group">
          <label for="first-name">First Name:</label>
          <input type="text" id="first-name" name="first_name" required>
        </div>
        <div class="form-group">
          <label for="last-name">Last Name:</label>
          <input type="text" id="last-name" name="last_name" required>
        </div>
        <div class="form-group">
          <label for="email">Email Address:</label>
          <input type="email" id="email" name="email" required>
        </div>
        <div class="form-group">
          <label for="phone">Phone Number (Optional):</label>
          <input type="tel" id="phone" name="phone">
        </div>
      </fieldset>
      <fieldset>
        <legend>Shopping Information</legend>
        <div class="form-group">
          <label for="address">Shopping Address:</label>
          <input type="text" id="address" name="shipping_address" required>
        </div>
        <div class="form-group">
          <label for="city">City:</label>
          <input type="text" id="city" name="city" required>
        </div>
        <div class="form-group">
          <label for="state">State:</label>
          <select id="state" name="state" required>
            <option value="">Select State</option>
            <option value="">Lagos</option>
            <option value="">Ogun</option>
            <option value="">Kwara</option>
            <option value="">Oyo</option>
            <option value="">Ekiti</option>
            <option value="">Enugu</option>
            </select>
        </div>
        <div class="form-group">
          <label for="zip">ZIP Code:</label>
          <input type="text" id="zip" name="zip" required>
        </div>
        <fieldset>
            <legend>Payment Information</legend>
            <div class="form-group">
            <label for="payment-method">Payment Method:</label>
            <div class="radio-group">
                <input type="radio" id="credit-card" name="payment_method" value="credit_card" required>
                <label for="credit-card">Credit Card</label>
                <input type="radio" id="debit-card" name="payment_method" value="debit_card">
                <label for="debit-card">Debit Card</label>
                <input type="radio" id="paypal" name="payment_method" value="paypal">
                <label for="paypal">PayPal</label>
            </div>
            </div>
            <div id="credit-card-details" class="hidden">
            <div class="form-group">
                <label for="card-number">Card Number:</label>
                <input type="text" id="card-number" name="card_number" required>
            </div>
                <div class="form-group">
                    <label for="expiration-month">Expiration Date:</label>
                    <select id="expiration-month" name="expiration_month" required>
                    <option value="">Month</option>
                    </select>
                </div>
            </div>
        </fieldset>
        <button type="submit" form="order-form" class="cancel">Cancel</button>
        <button type="submit" form="order-form">Place Order</button>
      </fieldset>
    </form>
  </div>
</template>

<script>
export default {

}
</script>

<style>

.order-form-container {
    width: 600px; 
    margin: 50px auto;
    padding: 20px;
    border: 2px solid black;
    border-radius: 5px;
    margin-top: 100px;
    font-family: 'Montserrat', sans-serif;
    color: black;
}

h2 {
text-align: center;
margin-bottom: 20px;
}

fieldset {
border: 1px solid #eee;
padding: 10px;
margin-bottom: 20px;
}

legend {
font-weight: bold;
padding: 5px;
}

label {
display: block;
margin-bottom: 5px;
padding: 5px 0px;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
width: 90%;
padding: 10px;
border: 1px solid black;
border-radius: 3px;
}

select {
width: 90%;
padding: 10px;
border: 1px solid black;
border-radius: 3px;
}

.radio-group {
display: flex;
}

.radio-group input[type="radio"] {
margin-right: 10px;
}

#order-summary p {
text-align: center;
}

#credit-card-details {
display: none;
}

#credit-card:checked ~ #credit-card-details,
#debit-card:checked ~ #credit-card-details {
display: block;
}

.cancel[type="submit"] {
    background-color: #ff0000;
    color: #fff;
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
}
  
.cancel[type="submit"]:hover {
    background-color: #fff;
    border: 1px solid #ff0000; 
    color: #ff0000; 
}

button[type="submit"] {
    background-color: #575b57; 
    color: #fff; 
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
}

button[type="submit"]:hover {
    background-color: white; 
    border: 1px solid #575b57;
    color: #575b57;
}
</style>