<template>
    <div class="popular">

        <h2>POPULAR LAMPS</h2>
        <div class="popular-lamp">
            <router-link to="/i" class="links">
                <div class="card">
                    <img src="../assets/24.jpeg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">COSMOS PENDANT</h3>
                        <p class="lamp-description">Pendant Lamp</p>
                        <p class="lamp-price">₦250,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/ii" class="links">
                <div class="card" >
                    <img src="../assets/1.jpeg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">JUTE TABLE</h3>
                        <p class="lamp-description">Table Lamp</p>
                        <p class="lamp-price">₦50,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/iii" class="links">
                <div class="card" >
                    <img src="../assets/12.jpeg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">NOC WALL LIGHT</h3>
                        <p class="lamp-description">Wall Lamp</p>
                        <p class="lamp-price">₦70,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/iv" class="links">
                <div class="card" >
                    <img src="../assets/f.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">BIRDY FLOOR</h3>
                        <p class="lamp-description">Floor Lamp</p>
                        <p class="lamp-price">₦65,000</p>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="popular-lamp">

            <router-link to="/v" class="links">
                <div class="card" >
                <img src="../assets/22.jpeg" alt="lamp" class="lamps" >
                <div class="lamp-details">
                    <h3 class="lamp-title">SWING ARM</h3>
                    <p class="lamp-description">Wall Lamp</p>
                    <p class="lamp-price">₦35,000</p>
                </div>
            </div>
            </router-link>
            
            <router-link to="/vi" class="links">
                <div class="card" >
                    <img src="../assets/19.jpeg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">CORAL REEF</h3>
                        <p class="lamp-description">Pendant Lamp</p>
                        <p class="lamp-price">₦150,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/vii" class="links">
                <div class="card" >
                    <img src="../assets/26.jpeg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">CAMPRANI BENTWOOD</h3>
                        <p class="lamp-description">Floor Lamp</p>
                        <p class="lamp-price">₦70,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/viii" class="links">
                <div class="card" >
                    <img src="../assets/4.jpeg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">WASTBERG NENDO </h3>
                        <p class="lamp-description">Table Lamp</p>
                        <p class="lamp-price">₦115,000</p>
                    </div>
                </div>
            </router-link>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'PopularPage',
    }
</script>

<style scoped>
.popular{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    padding: 100px 0px;
}

.popular h2{
    color: black;
    padding: 0px 0px;
    font-family: 'Montserrat', sans-serif;
}

.popular-lamp{
    display: flex;
    justify-content: center;
}

.card {
    width: 250px; 
    margin: 10px; 
    background-color: #ffffff; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    text-align: center;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
    border: 1px solid rgb(217, 217, 217);
}

.card:hover {
    transform: scale(1.05); 
}

.card img {
    width: 250px;
    height: 250px; 
    object-fit: cover; 
}
  
  
.lamp-details {
    color: black;
    text-align: left;
    padding: 10px;
}
  
.lamp-title {
    font-size: 20px;
    margin-bottom: 5px; 
    font-weight: 700;
}
  
.lamp-description {
    font-size: 14px; 
    margin-bottom: 10px; 
}
  
.lamp-price {
    font-weight: bold; 
    margin-bottom: 5px; 
    font-size: 20px;
}

.links {
    text-decoration: none;
}

@media (max-width: 768px) {
  .popular-lamp {
    flex-wrap: wrap; /* Wrap cards to stack vertically */
    justify-content: center; /* Center the cards horizontally */
  }

  .card {
    margin: 10px 0; /* Adjust margin for vertical stacking */
  }
}


</style>